
import React from 'react'
import {mdx} from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
/* @jsxFrag mdx.Fragment */
import {PageSection} from '../../../PageSections';
import img1 from '../../../illustrasjoner/aquanor2021.jpg?size=480';
export const frontMatter = {
  "title": "Du finner oss på Aqua Nor!",
  "timeToRead": "1 min read"
};
export const headings = [{
  "title": ["Du finner oss på Aqua Nor!"]
}];
const MDXLayout = "wrapper";
function MDXContent({components, ...props}) {
  return <MDXLayout components={components} {...props}><PageSection title="Du finner oss på Aqua Nor!" className="wrapper " mdxType="PageSection"><article className="content" parentName="PageSection"><div className="content__heading" parentName="article"><h1 className="md:text-700" parentName="div"><span parentName="h1">{"Du finner oss på Aqua Nor!"}</span>{" "}</h1></div><div className="content__content_wide flow-space-400 flow" parentName="article"><p parentName="div">{"Besøk TOOLS på stand D-382 under Aqua Nor og ta en kikk på vår løsning for en tryggere merdkant! TOOLS har en ny satsning mot havbruksnæringen, og vi er stolte over å være en del av laget på sikkerhetssiden!"}</p><img src={img1.src} width={img1.width} height={img1.height} parentName="div" /></div><div className="content__media" parentName="article" /></article></PageSection></MDXLayout>;
}
MDXContent.isMDXComponent = true;
export default MDXContent;
