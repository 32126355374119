import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useContext,
  createContext,
} from 'react';
import {
  useLocation
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import slugify from '@sindresorhus/slugify';
import 'focus-visible';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import MDXTheme from './mdx-theme';

import { PageSectionProvider, ActiveSectionContext } from './PageSections';

import Logo from "./illustrasjoner/logo.svg?component";

import getDirectories from './directories';

const directories = getDirectories();

const flatten = (list) => {
  return list.reduce((flat, toFlatten) => {
    return flat.concat(
      toFlatten.children.length ? flatten(toFlatten.children) : []
    );
  }, [list[0]]);
};

const flatDirectories = flatten([directories]);

const MenuCbCallbackContext = createContext();


function Menu({ anchors }) {
  const [menuCbToggle, menuCbClose] = useContext(MenuCbCallbackContext);
  const activeSection = useContext(ActiveSectionContext);
  return (
    <nav className="[ nav ] [ site-head__nav ] [ font-sans ]" aria-label="Primary navigaton">
      <ul className="nav__list">
        {anchors.map((anchor) => {
          const slug = slugify((anchor.shortTitle || anchor.title || ['Untitled']).join(' ') || '');
          return (
            <li key={`a-${slug}`} className={cn({ 'active': slug === activeSection })}>
              <a
                href={'#' + slug}
                aria-current={slug === activeSection ? 'page' : null}
                onClick= {menuCbClose}
              >
                {(anchor.shortTitle || anchor.title || ['Untitled']).map(part => (
                  <span key={`a-${part}`}>{part}</span>
                ))}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>

  );
}

const Layout = ({ anchors, children }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const menuCbToggle = () => {
    setMenuOpen((state) => {
      return !state
    })
  }

  const menuCbClose = () => {
    setMenuOpen(false)
  }
  const router = useLocation();
  const { pathname } = router;


  const currentIndex = useMemo(
    () => flatDirectories.findIndex((dir) => dir.path === pathname.slice(1)),
    [flatDirectories, pathname]
  );

  const title = flatDirectories[currentIndex]?.meta?.title || 'Untitled';

  return (
    <>
      <MenuCbCallbackContext.Provider value={[menuCbToggle, menuCbClose]}>
        <PageSectionProvider>
          <Helmet>
            <title>
              {title}
            </title>
          </Helmet>
          <a className="[ skip-link ] [ button ]" href="#main-content">Hopp til innhold</a>
          <div className={cn('site-wrap', {
            'menu-open': menuOpen
          })}>
            <header role="banner" className="site-head">
              <div className="site-head__wrapper">
                <a href="/" aria-label="aquasecure - hjem" className="site-head__brand">
                  <Logo />
                </a>
                <FontAwesomeIcon onClick={menuCbToggle} icon={menuOpen ? faTimes : faBars} className="site-head__menutoggle" />
                <div className="site-head__inner">
                  <div className="site-head__menu__wrapper">
                    <Menu anchors={anchors} />
                  </div>
                </div>
              </div>
            </header>
            <main tabIndex="-1" id="main-content" className="flow-space-major flow">
              <MDXTheme>{children}</MDXTheme>
            </main>
          </div>
        </PageSectionProvider>
      </MenuCbCallbackContext.Provider>
    </>
  );
};

export default Layout;