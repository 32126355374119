import React from "react";
import VideoPlayer from "./Video";

export default function App() {
  return (
    <div>
      <VideoPlayer
        src={
          __webpack_public_path__.replace("3001", "3000")+"aquasecureas/playlist.m3u8"
        }
        controls={
          true
        }
        autoplay={
          false
        }
        loop={
          false
        }
      />
    </div>
  );
}

