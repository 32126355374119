import "./App.scss";

import React from "react";
import { Route, Switch } from "react-router-dom";

import { Helmet } from "react-helmet";
import Layout from './layout';
import getDirectories from './directories';
/* import getConfig from './config';
const config = getConfig(); */
const directories = getDirectories();

const flatten = (list) => {
  return list.reduce((flat, toFlatten) => {
    return flat.concat(
      toFlatten.children.length ? flatten(toFlatten.children) : []
    );
  }, [list[0]]);
};

const flatDirectories = flatten([directories]);

const App = () => (
  <>
    <Switch>
      {/* <Route path="/" exact component={Home} /> */}
      {flatDirectories.filter(directory => !!directory.component).map(directory => (
        <Route key={directory.path}  path={"/" + directory.path} exact>
          <Layout anchors={flatDirectories[0].headings}>
            <directory.component />
          </Layout>
        </Route>
      ))}
    </Switch>
  </>
);

export default App;
